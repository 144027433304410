import { Injectable } from '@angular/core';
import {ActionLinkAPIResourceV2, PromotionApiResourceV2} from "./generated/cronos/resources";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class PhoneVerificationService {

  token: string
  phoneAuthCode: string

  constructor(private route: ActivatedRoute,
              private actionLinkResource: ActionLinkAPIResourceV2,
              private router: Router
  ) {

    //listens to query params and if both a code and phoneAuthCode is present then verify phone number
    this.route.queryParamMap.subscribe(params => {

      if (params.has("code") && params.has("phoneAuthCode")) {

        //if the code is an assignToPromotion code (only for sign-up action link route) then call different endpoint
        if (this.router.url.includes('/sign-up')) {
          this.validatePhoneNumberByAssignToPromotionCode(params.get("code"), params.get("phoneAuthCode"))
        } else {
          this.validatePhoneNumberByPromRegStateCode(params.get("code"), params.get("phoneAuthCode"))
        }

      }

    })
  }

  validatePhoneNumberByPromRegStateCode(token: string, authCode: string): Promise<any> {
    return this.actionLinkResource.validatePhoneNumberByPromRegStateCode({code: token, authCode: authCode}).then(result => {})
  }

  validatePhoneNumberByAssignToPromotionCode(token: string, authCode: string): Promise<any> {
    return this.actionLinkResource.validatePhoneNumberByAssignToPromotionCode({code: token, authCode: authCode}).then(result => {})
  }

}
