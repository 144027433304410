<div id="checkout-header" fxLayout="row" fxLayoutAlign="space-between center" style="font-size: 20px">
  <!-- <h3 fxLayout="row" fxLayoutAlign="center center"><strong>Passende Termine für dich</strong></h3> -->
  <h3><strong>Passende Termine für dich</strong></h3>
  <button class="hid-icon-button inverse ita_close_checkout-modal" (click)="globalCheckoutService.returnToOfferPage()">
    <i
      class="fas fa-times"></i>
  </button>
</div>

<div class="s4-checkout-body">
  <div class="date-list">
    <hid-loading-spinner [loadingBool]="loading">
      <!--<div *ngFor="let weekItem of segmentedDates">
        <div *ngIf="weekItem.dates.length > 0">
          <div class="week-header w-100" *ngIf="weekItem.weeksFromNow == 0">Diese Woche</div>
          <div class="week-header w-100" *ngIf="weekItem.weeksFromNow == 1">Nächste Woche</div>
          <div class="week-header w-100" *ngIf="weekItem.weeksFromNow > 1">In {{weekItem.weeksFromNow}} Woche<span
            *ngIf="weekItem.weeksFromNow > 1">n</span></div>
          <div class="dateItem" fxLayout="row" fxLayoutAlign="space-between center"
               *ngFor="let promotion of weekItem.dates">
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutAlign="start center" style="white-space: nowrap;"><i fxHide.xs
                                                                                               class="fa fam fa-calendar-alt"></i>  {{promotion.date | date : 'EEE, dd.MM.yyyy'}}</div>
              <div fxLayout="row" fxLayoutAlign="start center"><i fxHide.xs
                                                                  class="far fam fa-clock"></i>
                {{promotion.date | date : 'HH:mm'}}
                -
                {{promotion.endTime | date : 'HH:mm'}}
              </div>
              <div fxHide fxShow.xs style="color: #777;font-style: italic;font-size: 12px">
                <span *ngIf="promotion.type === 'OfflineSeminarDate'"><i
                  class="fa fam fa-map-marker-alt"></i>Vor Ort</span>
                <span *ngIf="promotion.type.indexOf('WebinarDate') > -1"> <i
                  class="fas fa-globe fam"></i>Online-Termin</span>
              </div>
            </div>
            <div class="isu-padding-2x" fxHide.xs style="color: #777;">
              <span *ngIf="promotion.type === 'OfflineSeminarDate'"><i
                class="fa fam fa-map-marker-alt"></i>{{promotion.address}}</span>

              <span *ngIf="promotion.type.indexOf('WebinarDate') > -1" ><i
                class="fas fa-globe fam"></i>Online-Termin</span>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center">
              <button class="hid-button secondary outline expand"
                      (click)="selectDate(promotion)">
                Auswählen
              </button>
              <span class="freeSlots" *ngIf="promotion.freeSlots == 1">{{promotion.freeSlots}} Platz frei</span>
              <span class="freeSlots" *ngIf="promotion.freeSlots != 1">{{promotion.freeSlots}} Plätze frei</span>
            </div>
          </div>
        </div>
      </div>-->

      <app-checkout-seminardate-segmentation *ngIf="dates.length > 0" [seminarDates]="dates"
                                             [selectedDateSubject]="promotionSubject"
                                             [displayedSeminarAmountSubject]="totalDisplayedSeminarsAmountSubject"></app-checkout-seminardate-segmentation>
      <div *ngIf="offerWaitinglist" class="no-dates-container">
        <i class="mdi mdi-calendar-remove-outline"></i>
        <div class="w-100" style="margin-top: 10px;text-align: center" fxLayout="row"
             fxLayout.xs="column" fxLayoutAlign="center center">
          <div style="margin-right: 5px;">Keinen Platz gefunden?</div>
          <div> Jetzt in die <span class="link-button" (click)="enableWaitingList()">Warteliste</span> eintragen.</div>
        </div>
      </div>

    </hid-loading-spinner>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button class="hid-button inverse secondary ita_step-back" (click)="back()">Zurück</button>
  </div>
</div>
