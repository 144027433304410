import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {saveAs} from 'file-saver/dist/FileSaver.js';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ContactSupportModalComponent
} from '../../../contact-support-modal/contact-support-modal/contact-support-modal.component';
import {ActionLinkAPIResourceV2} from '../../../generated/cronos/resources';
import {RegistrationInfoData} from '../../../generated/cronos/data';
import {PublicOfferPageResource} from '../../../generated/cms/resources';
import {RegistrationInfoService} from '../registration-info.service';
import {DateUtilsService} from "../../../inno-utils/dateutils.service";

@Component({
  selector: 'app-download-giveaway-action-link',
  templateUrl: './download-giveaway-action-link.component.html',
  styleUrls: ['./download-giveaway-action-link.component.scss']
})
export class DownloadGiveawayActionLinkComponent implements OnInit {

  token: string;

  iteminfo: RegistrationInfoData = <RegistrationInfoData> {};
  recommendedUpsellPageIds: number[] = [];

  loadingInfo = true;
  downloading = false;
  downloaded = false;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private modal: NgbModal,
    private cronosApi: ActionLinkAPIResourceV2,
    private registrationInfoService: RegistrationInfoService,
    private offerPageResource: PublicOfferPageResource,
    @Inject('env') private environment,
    public dateUtils: DateUtilsService
  ) {

    route.snapshot.queryParamMap.get('code') ? this.token = route.snapshot.queryParamMap.get('code') : this.token = route.snapshot.paramMap.get('token');
    this.getItemInfo();
  }

  ngOnInit() {
  }


  getItemInfo() {
    this.loadingInfo = true;

    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(res => {
      this.iteminfo = res;
      this.loadingInfo = false;
      //TODO could make one request for that but yolo for now

      this.offerPageResource.getFormatPageForSyncedFormat({syncedFormatId: res.promotionData.formatId}).then(res => {
        if (res.offerPageId) {
          this.offerPageResource.getOfferPageUpsellRecommendations(res.offerPageId).then(upsells => {
            this.recommendedUpsellPageIds = upsells;
          });
        }

      });


    });


  }

  download() {
    this.downloading = true;

    this.cronosApi.getGiveawayInfo(this.token).then(res => {
      this.http.get(this.environment.cronosApiUrl + '/apiv2/download/' + this.token + '/content', {
        headers: new HttpHeaders(),
        responseType: 'blob'
      }).subscribe(
        response => {
          this.downloaded = true;
          saveAs(response, res.fileName);
        }
      );
    });

  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }
}
