<!--Feedback not already given -->
<div *ngIf="feedbackDataLoaded && seminarFeedbackPageData.feedbackEntered != true" id="feedback-main-wrapper"
     class="ita-format-{{seminarFeedbackPageData.promotionData.formatId}}"
     fxLayout="column" fxLayoutAlign="start center">
  <div id="logo">
    😕
  </div>

  <h1>Schade, dass du nicht da warst!</h1>

  <div class="student-was-present" fxLayout="column" fxLayoutAlign="start center">
    <div class="link" (click)="openConfirmDialog()">Du warst beim {{seminarFeedbackPageData.promotionData.promotionType | promotionType}}? Klicke hier.</div>
  </div>

  <app-promotion-info-box [promotionData]="seminarFeedbackPageData.promotionData"></app-promotion-info-box>

  <div id="reason-box">
    <div>
      <div class="reason" fxLayout="row" fxLayoutAlign="start center">
        Bitte gib einen Grund an, damit wir unseren Service verbessern können:
      </div>
    </div>

    <hid-radio-group *ngIf="seminarFeedbackPageData?.promotionData?.promotionType == 'OfflineSeminarDate'" [options]="radioOptionsForOfflineSeminar" [control]="feedbackGroup.controls['reason']"></hid-radio-group>
    <hid-radio-group *ngIf="seminarFeedbackPageData?.promotionData?.promotionType.indexOf('WebinarDate') > -1" [options]="radioOptionsForWebinar" [control]="feedbackGroup.controls['reason']"></hid-radio-group>

    <div class="text-area">
      <hid-textarea
        label="Kommentar"
        minRows="5"
        maxLength="500"
        charCounterShow="true"
        [control]="feedbackGroup.controls['reasonText']"></hid-textarea>
    </div>

    <app-anonymize-feedback [anonymizeControl]="feedbackGroup.controls['anonymized']"></app-anonymize-feedback>

    <div id="action-box" fxLayout="column" fxLayoutAlign="center end">
      <div *ngIf="errorMessage && !reasons.includes(feedbackGroup.controls.reason.value?.value)" class="error-message">
        Bitte gib erst einen Grund ab!
      </div>
      <button [disabled]="feedbackSend" class="hid-button primary ita-send-feedback-not-present-btn" (click)="sendFeedback()">Feedback senden</button>
    </div>
  </div>
</div>


<!-- Feedback was already given -->
<div class="actionlink-page-wrapper" *ngIf="seminarFeedbackPageData?.feedbackEntered == true" fxLayout="column" fxLayoutAlign="start center">
  <div class="actionlink-main-box">
    <div id="logo"style="text-align: center;">
      😕
      <!--img src="../../../../../assets/img/wrongSmiley.jpg"-->
    </div>

    <div class="explanation">Du hast schon Feedback zu deinem Seminar abgegeben. Vielen Dank!
    </div>

    <app-promotion-info-box [promotionData]="seminarFeedbackPageData.promotionData"></app-promotion-info-box>

    <div fxLayout="row" fxLayoutAlign="center center">
      <button class="hid-button secondary inverse" (click)="goHome()">Zur Startseite</button>
    </div>
  </div>
</div>

<div class="hid-to-ta-wrapper" *ngIf="seminarFeedbackPageData?.promotionData?.formatId && dateUtils.isWithinNextFullYear(seminarFeedbackPageData?.contactData?.studyEndYear)">
  <app-hid-to-talentagent-banner [utmCampaign]="'feedback-student-not-present-feedback'"
                                 [formatId]="seminarFeedbackPageData?.promotionData?.formatId">
  </app-hid-to-talentagent-banner>
</div>

<div fxLayout="column" fxLayoutAlign="start center"
     *ngIf="recommendedUpsellPageIds.length > 0 && !dateUtils.isWithinNextFullYear(seminarFeedbackPageData?.contactData?.studyEndYear)">

  <h1>Das könnte dich auch interessieren:</h1>
  <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
    <app-offer-page-panel
      [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
      [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
    </app-offer-page-panel>
  </div>
</div>

<div *ngIf="recommendedUpsellPageIds.length == 0 && !dateUtils.isWithinNextFullYear(seminarFeedbackPageData?.contactData?.studyEndYear)" fxLayout="column"
     fxLayoutAlign="center center" id="to-main-page">
  <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich!</div>
  <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
</div>



