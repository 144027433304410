import { Injectable } from '@angular/core';
import {LocalStorageService} from "../../local-storage.service";
import {PromotionApiResourceV2} from "../../generated/cronos/resources";
import {GlobalCheckoutService} from "../global-checkout.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AECDiscCheckoutService {

  constructor(private localStorageService: LocalStorageService,
              private promotionApiV2Resource: PromotionApiResourceV2,
              private globalCheckoutService: GlobalCheckoutService,
              private router: Router
  ) { }

  completeCheckout(hidSplit: string) {
    let data = this.globalCheckoutService.buildEnterPromotionData();

    const cronosFormatId = this.globalCheckoutService.getCurrentPage().cronosFormatId;

    let thankYouQueryParams = {
      pid: this.globalCheckoutService.getCurrentPage().pageId,
      fid: cronosFormatId,
    };

    return this.promotionApiV2Resource.enterPromotionByFormatId(
      data, cronosFormatId
    ).then(() => {
      this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {hidSplit: hidSplit});
      this.router.navigate(['thank-you'], {queryParams: thankYouQueryParams});
    });
  }
}
