import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  constructor() { }

  isWithinNextFullYear(fullYear: number): boolean {
    return fullYear <= new Date().getFullYear() + 1
  }

}

