<div class="actionlink-page-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div class="actionlink-main-box">
        <div *ngIf="!seminarFeedbackPageDataLoading">
            <h1><i class="mdi mdi-check-circle-outline" style="font-size: 64px"></i><br>Danke für dein Feedback!
                <div *ngIf="feedbackWasPositive && registrationInfo.promotionData.formatId != 2324 && !permissionToDownloadCertificate">
                    Wir haben dir deine
                    Seminarunterlagen per Mail geschickt
                </div>
                <div *ngIf="feedbackWasPositive && permissionToDownloadCertificate"
                     style="font-size: 23px; font-weight: normal;">Deine Seminarunterlagen und dein Zertifikat
                    haben wir dir per Mail geschickt
                </div>
                <div *ngIf="registrationInfo.promotionData.formatId == 2324 && feedbackWasPositive"><i
                        class="mdi mdi-check-circle-outline" style="font-size: 64px"></i>Wir haben dir dein
                    E-Book per Mail geschickt
                </div>

            </h1>

            <!-- trust pilot feedback  -->
<!--          ONLY COMMENTED OUT TEMPORARILY -->
<!--            <div *ngIf="feedbackWasPositive" class="misc-box">-->
<!--                Wir würden uns riesig freuen, wenn du deine Bewertung auch bei Trustpilot teilst!<br><br>-->
<!--                <a class="trustpilot-cta " [href]="goToTrustPilotReview()" fxLayout="row" fxLayoutAlign="center center">-->
<!--                    <img ngSrc="assets/img/trustpilot-logo.png" height="20" width="20">-->
<!--                    <span>bewerten</span>-->
<!--                </a>-->
<!--            </div>-->

          <!-- uniwunder google review -->
          <div *ngIf="feedbackWasPositive" class="misc-box">
            Wir würden uns riesig freuen, wenn du deine Bewertung auch bei Google teilst!<br><br>
            <a class="google-cta " [href]="goToUniwunderGoogleReview()" fxLayout="row" fxLayoutAlign="center center">
              <img ngSrc="assets/img/google-logo-icon.png" height="20" width="20">
              <span>bewerten</span>
            </a>
          </div>



            <!-- route to download course materials -->
            <div *ngIf="registrationInfo.contactData != undefined && !feedbackWasPositive" fxLayout="column"
                 fxLayoutAlign="center center" fxLayoutGap="10px">
                <div id="download-button">
                    <button class="hid-button primary button ita-download-handout-btn" fxLayout="column"
                            fxLayoutAlign="center center"
                            (click)="navigateToHandout()">
                        <div *ngIf="registrationInfo.promotionData.formatId != 2324"> Hier Unterlagen downloaden <i
                                class="fas fa-file-download icon"></i></div>
                        <div *ngIf="registrationInfo.promotionData.formatId == 2324"> Hier E-Book downloaden <i
                                class="fas fa-file-download icon"></i></div>
                    </button>
                </div>
                <div class="misc-box">
                    <strong>{{registrationInfo.contactData.firstName}}, wir haben da übrigens noch was für dich
                        🤫</strong><br>
                    Sichere dir jetzt dein Allround-Präsentations-Paket inkl. PowerPoint Vorlage, Checkliste und Tipps
                    für die
                    perfekte Präsi in der Uni!<br>
                    <a href="/angebote/kostenlose-praesentationsvorlage"><strong>Zu den
                        Präsentationsvorlagen</strong></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="hid-to-ta-wrapper" *ngIf="registrationInfo?.promotionData?.formatId && dateUtils.isWithinNextFullYear(registrationInfo?.contactData?.studyEndYear)">
  <app-hid-to-talentagent-banner [utmCampaign]="'feedback-thank-you'"
                                 [formatId]="registrationInfo?.promotionData?.formatId">
  </app-hid-to-talentagent-banner>
</div>


<div id="upsell" fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0 && !dateUtils.isWithinNextFullYear(registrationInfo?.contactData?.studyEndYear)">

        <h1>Das könnte dich auch interessieren:</h1>
        <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
            <app-offer-page-panel
                    [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
                    [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
            </app-offer-page-panel>
        </div>
    </div>

    <div *ngIf="recommendedUpsellPageIds.length == 0 && !dateUtils.isWithinNextFullYear(registrationInfo?.contactData?.studyEndYear)" fxLayout="column" fxLayoutAlign="center center"
         id="to-main-page">
        <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich</div>
        <img src="../../../../../assets/img/seminars2.jpg">
        <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
    </div>
</div>

