import {Injectable} from '@angular/core';
import {PublicSeminarData} from '../../generated/cronos/data';
import {PromotionApiResourceV2, WaitingListAPIResourceV2} from '../../generated/cronos/resources';
import {GlobalCheckoutService} from '../global-checkout.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../local-storage.service';
import {InternalCookieService} from '../../internal-cookie.service';

@Injectable({
  providedIn: 'root'
})
export class SeminarCheckoutService {

  private selectedSeminar: PublicSeminarData;
  waitingList = false;

  constructor(private localStorageService: LocalStorageService,
              private promotionApiV2Resource: PromotionApiResourceV2,
              private globalCheckoutService: GlobalCheckoutService,
              private router: Router,
              private internalCookieService: InternalCookieService,
              private waitingListApiResource: WaitingListAPIResourceV2) {
    this.selectedSeminar = localStorageService.getSelectedSeminar();
  }

  getSeminar() {
    return this.selectedSeminar;
  }

  setSeminar(seminar: PublicSeminarData) {
    this.selectedSeminar = seminar;
    this.waitingList = false;
    this.localStorageService.setSelectedSeminar(seminar);
  }

  clearSelectedSeminar() {
    this.setSeminar(<PublicSeminarData> {});
  }

  completeCheckout(hidSplit: string) {
    let data = this.globalCheckoutService.buildEnterPromotionData();

    const currentPage = this.globalCheckoutService.getCurrentPage();

    let thankYouQueryParams = {
      pid: currentPage.pageId,
      sid: this.selectedSeminar.id,
      fid: currentPage.cronosFormatId,
      waitingListSignup: this.waitingList.toString()
    };

    if (this.waitingList) {
      return this.waitingListApiResource.enterWaitingList(
        data, currentPage.cronosFormatId
      ).then(() => {
          this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {waitingList: this.waitingList.toString(), hidSplit: hidSplit});
          this.router.navigate(['thank-you'], {queryParams: thankYouQueryParams});
        }
      );
    } else {
      return this.promotionApiV2Resource.enterSeminarWithNewContactData(
        data, this.selectedSeminar.id
      ).then(cookieIdentifier => {
        this.globalCheckoutService.reportTrackingEvent('CheckoutCompleted', {waitingList: this.waitingList.toString(), hidSplit: hidSplit});
        this.internalCookieService.storeSeminarCookieIdentifier(this.selectedSeminar.id, cookieIdentifier.value);
        this.router.navigate(['thank-you'], {queryParams: thankYouQueryParams});
      });
    }


  }

}
