import {Component, OnInit} from '@angular/core';
import {RegistrationInfoData} from '../../../generated/cronos/data';
import {ActionLinkAPIResourceV2} from '../../../generated/cronos/resources';
import {ActivatedRoute, Router} from '@angular/router';
import {PublicOfferPageResource} from '../../../generated/cms/resources';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ContactSupportModalComponent
} from '../../../contact-support-modal/contact-support-modal/contact-support-modal.component';
import {RegistrationInfoService} from '../registration-info.service';
import {ShareService} from '../../../share/share.service';
import {DateUtilsService} from "../../../inno-utils/dateutils.service";

@Component({
  selector: 'app-promotion-state-confirm-action-link',
  templateUrl: './promotion-state-confirm-action-link.component.html',
  styleUrls: ['./promotion-state-confirm-action-link.component.scss']
})
export class PromotionStateConfirmActionLinkComponent implements OnInit {


  token: string;
  loading: boolean = true;
  registrationInfo: RegistrationInfoData;
  page;

  constructor(private actionLinkAPIResourceV2: ActionLinkAPIResourceV2,
              private route: ActivatedRoute,
              private router: Router,
              private registrationInfoService: RegistrationInfoService,
              private offerPageResource: PublicOfferPageResource,
              private shareService: ShareService,
              public dateUtils: DateUtilsService,
              private modal: NgbModal) {
    route.snapshot.queryParamMap.get('code') ? this.token = route.snapshot.queryParamMap.get('code') : this.token = route.snapshot.paramMap.get('token');
  }

  ngOnInit() {
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(res => {
      this.registrationInfo = res;
      if (this.registrationInfo.registrationState == 'Confirmed') {
        this.confirmed = true;
      }
      this.loading = false;
      this.offerPageResource.getFormatPageForSyncedFormat({syncedFormatId: res.promotionData.formatId}).then(res => {
        this.page = res;
      });
    });
  }

  confirmed = false;
  confirming = false;

  confirmRegistration() {
    this.confirming = true;
    this.actionLinkAPIResourceV2.confirmRegistration({value: this.token}).then(() => {
      this.confirming = false;
      this.confirmed = true;

    });
  }

  openShare() {
    let params = [];
    if (this.registrationInfo.promotionData.promotionType.indexOf('inarDate') > -1) {
      params.push({
        key: 'psid',
        value: this.registrationInfo.promotionData.id
      });
    }
    let shareText = 'Hey, mach doch mit mir bei diesem Workshop mit 😊:';
    if (this.registrationInfo.promotionData.formatId = 2313) {
      shareText = 'Hey, mach doch mit mir bei diesem Event mit 😊:';
    }
    this.shareService.showSharingButtons('/angebote/' + this.page.routingUrl, 'confirm-share', shareText, params);
  }

  openSupportModal() {
    const modalRef = this.modal.open(ContactSupportModalComponent);
  }

}
