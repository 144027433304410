<div id="header">
  <div id="header-content" fxLayout="column" fxLayoutAlign="center end">
    <div fxLayout="column" fxLayoutAlign="center start" class="isu-padding">
      <p>Dein Download</p>
    </div>
  </div>
</div>
<div class="content-wrapper">
  <hid-loading-spinner [loadingBool]="loadingInfo">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="iteminfo.promotionData">
      <div  class="itemInfo" fxLayout="column">
        <h2>Dein Download ist bereit!</h2>
        <p>Inhalt: {{iteminfo.promotionData.formatTitle}}</p>
        <button [disabled]="downloading" class="hid-button primary" (click)="download()">
          <span *ngIf="!downloaded"><i class="fa fa-download"></i> Herunterladen</span>
          <span *ngIf="downloaded" class="text-green"><i class="fa fa-check "></i> Download abgeschlossen</span>

        </button>
      </div>
    </div>
  </hid-loading-spinner>
</div>

<div *ngIf="iteminfo?.promotionData?.formatId && dateUtils.isWithinNextFullYear(iteminfo?.contactData?.studyEndYear)">
  <app-hid-to-talentagent-banner [utmCampaign]="'download'"
                                 [formatId]="iteminfo?.promotionData?.formatId">
  </app-hid-to-talentagent-banner>
</div>

<div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0 && !dateUtils.isWithinNextFullYear(iteminfo?.contactData?.studyEndYear)">

  <h1>Das könnte dich auch interessieren:</h1>
  <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
    <app-offer-page-panel [queryParams]="{utm_source:'hidpage', utm_campaign:'downloadpage-offer-caroussel',utm_medium:'downloadpage'}"
                          [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds"></app-offer-page-panel>
  </div>
</div>


