import { Component, OnInit } from '@angular/core';
import {PublicFormatPageData} from "../../../../generated/cms/data";
import {ActivatedRoute, Router} from "@angular/router";
import {GlobalCheckoutService} from "../../../global-checkout.service";
import {AECDiscCheckoutAEC1ProcessService} from "../aecdisc-checkout-aec1-process.service";

@Component({
  selector: 'app-aec1-checkout-location',
  templateUrl: './aec1-checkout-location.component.html',
  styleUrls: ['./aec1-checkout-location.component.scss']
})
export class AEC1CheckoutLocationComponent implements OnInit {

  page: PublicFormatPageData

  constructor(
    private router: Router,
    public globalCheckoutService: GlobalCheckoutService,
    private aec1ProcessService: AECDiscCheckoutAEC1ProcessService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.checkForForewarding()) {
      this.reportReached(true);
      this.next(true);
      return;
    }
    this.reportReached(false);

    window.scrollTo(0, 0);
    this.globalCheckoutService.forewardingEnabled = false;
  }

  reportReached(skip: boolean) {
    this.aec1ProcessService.reportTrackingEvent('LocationReached', {'skip': JSON.stringify(skip)});
  }


  checkForForewarding() {
    if (!this.globalCheckoutService.forewardingEnabled) {
      return false;
    }
    //location has already been selected and stored, possibly in a prior checkout
    if (this.globalCheckoutService.getCurrentUser() && this.globalCheckoutService.getCurrentUser().locationId != null) {
      return true;
    }
    return false;
  }

  next(skip: boolean = false) {
    this.aec1ProcessService.reportTrackingEvent('LocationSelected', {'skip': JSON.stringify(skip)});
    this.router.navigate([this.aec1ProcessService.prepareSplitRoutingUrl(this.route.parent, 'checkout/aec/aec1/user')]);
  }

}
