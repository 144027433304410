import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import { AECDiscCheckoutGatewayComponent } from './aecdisc-checkout-gateway/aecdisc-checkout-gateway.component';
import {AECDiscCheckoutAEC1Module} from "./aecdisc-checkout-aec1/aecdisc-checkout-aec1.module";

const appRoutes: Routes = [{path: 'angebote/:routingUrl/checkout/aec/g', component: AECDiscCheckoutGatewayComponent}]

@NgModule({
  declarations: [
    AECDiscCheckoutGatewayComponent
  ],
  imports: [
    CommonModule,
    AECDiscCheckoutAEC1Module,
    RouterModule.forChild(appRoutes)
  ]
})
export class AECDiscCheckoutModule { }
