<!-- TODO this is a mess and needs refactor -->

<!--
This is a header that informs the student about using correct name and turning on cam. Imo, it would be better
to make step between "Join" and google meet where he can confirm these hints, but that might affect joinrate so
i just removed this for now until someone complains about quality.
<div id="header" >
  <div fxLayout="row" fxLayoutAlign="start start" style="height: 100%;">
    <div fxLayoutAlign="center end" fxLayout="column" class="tips-icons">
      &lt;!&ndash; <i class="fas fa-user-secret fullname-icon" style="font-size: 60px; color: white; margin-right: 30px"></i> &ndash;&gt;
      <div style="height: 50%; display:flex; justify-content: center; align-items: center">
        <i class="fa fa-check-circle fullname-icon" style="font-size: 30px; color: white; margin-right: 30px;"></i>
      </div>
      <div style="height: 50%; display:flex; justify-content: center; align-items: center">
        <i class="fa fa-check-circle fullname-icon" style="font-size: 30px; color: white; margin-right: 30px;"></i>
      </div>
    </div>
    <div style="height: 100%;"  class="tips-text">
      <div id="header-content" fxLayout="column" style="height: 100%; width: 100%" class="mobile-display-inherit">
        <div fxLayout="column" fxLayoutAlign.lt-sm="start start" fxLayoutAlign="center start" class="isu-padding mobile-display-inherit" style="height: 100%;">
          &lt;!&ndash; <h1 class="mobile-hidden">Beim Seminar mit deinem Namen anmelden</h1> &ndash;&gt;
          <div style="height: 50%; display:flex; align-items: center; width: 100%">
            <div fxLayout="row" fxLayoutAlign.lt-sm="start start" fxLayoutAlign="start center" class="w-75 mobile-full-width">
              <p class="mobile-hidden" style="margin: inherit !important;">Bitte verwende beim Seminar <b>deinen richtigen Namen</b>, damit der Referierende dich bei Fragen oder Rückmeldungen direkt ansprechen kann.</p>
              <p class="desktop-hidden" style="margin: inherit !important;">Bitte verwende beim Seminar <b>deinen richtigen Namen</b>.</p>
            </div>
          </div>

          <div style="height: 50%; display:flex; align-items: center">
            <div fxLayout="row" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center" class="w-75 mobile-full-width">
              <p style="margin: inherit !important;">Vergiss nicht beim Start des Seminars deine <b>Kamera anzumachen</b>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->


<div class="actionlink-page-wrapper">
  <div class="actionlink-main-box">
<hid-loading-spinner [loadingBool]="loadingInfo">
  <div  fxLayout="column" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="start start" class="join-info-content-wrapper" style="width: 100%">
      <div fxLayout="column" fxLayoutAlign="start start" style="width: 100%; height: 100%">

        <div fxLayout="column" fxLayoutAlign="center center"
             style="width: 100%; justify-content: center; align-items: center; display: flex;">

          <div class="itemInfo" *ngIf="token != 'null'">
            <div  fxLayout="column" fxLayoutAlign="center center" style="width: 100%; justify-content: center; align-items: center; display: flex; min-height: 110px;">
              <svg *ngIf="step != 'delayForHint'" version="1.1" id="play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="100px" width="100px"
                   viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"
              >
                <path  class="stroke-solid" fill="none" stroke="#ff6400"  d="M49.9,2.5C23.6,2.8,2.1,24.4,2.5,50.4C2.9,76.5,24.7,98,50.3,97.5c26.4-0.6,47.4-21.8,47.2-47.7
              C97.3,23.7,75.7,2.3,49.9,2.5"/>
                <path class="icon" fill="#ff6400" d="M38,69c-1,0.5-1.8,0-1.8-1.1V32.1c0-1.1,0.8-1.6,1.8-1.1l34,18c1,0.5,1,1.4,0,1.9L38,69z"/>
              </svg>
              <h1 class="delay-countdown"  *ngIf="step == 'delayForHint' && delayCountDown > 0">{{delayCountDown}}</h1>
              <h1 class="delay-checkmark"  *ngIf="step == 'delayForHint' && delayCountDown == 0"><i class="mdi mdi-check"></i></h1>
            </div>



            <!-- VALIDATE PHONE NUMBER STEP -->
            <div *ngIf="step == 'enterCode'" class="itemInfoText height-join">
              <h2>Webinar Check-In</h2>
              <p>Bitte bestätige zum Beitritt deine Telefonnummer. Fordere dazu jetzt deinen Zugangscode an und gib ihn unten ein.</p>

              <div fxLayout="column" fxLayoutAlign="space-between center" fxLayout.xs="column">
                <app-hid-input-phone-number [showValidatorIcons]="true"
                                            style="width: 100%"
                                            inputFieldClass="ita_input_phone"
                                            name="phoneNumber" type="tel" [control]="phoneNumberControl"
                                            label="Deine Telefonnummer"
                ></app-hid-input-phone-number>

                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center" style="width: 100%;">

                  <button class="hid-button secondary" (click)="requestAuthCode()" style="margin-top:10px">
                    <span *ngIf="!codeRequested">Code jetzt senden</span>
                    <span *ngIf="codeRequested">Erneut senden</span>
                  </button>
                </div>
              </div>

              <div fxLayout="column" style="margin-top: 20px; border-top: 1px solid #e8e8e8;padding-top: 20px;">
                <hid-input-field label="Gib deinen Code hier ein" [control]="codeInputFromUser"></hid-input-field>
              </div>

              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
                <button [disabled]="checkingCode" class="hid-button primary" style="margin-top:10px" (click)="checkCode()">
                  Telefonnummer verifizieren
                </button>
              </div>
            </div>


            <!-- ENTER WEBINAR STEP -->

            <div class="itemInfoText height-join" *ngIf="step == 'enterWebinar' && iteminfo">
              <h2>Webinar beitreten</h2>
              <p>Hier kannst du dem Webinar <strong class="no-wrap">"{{iteminfo.title}}"</strong>
                <span class="no-wrap">({{iteminfo.date | date: "dd.MM.yy, HH:mm"}} Uhr)</span> beitreten.</p>

              <!-- Device hint -->
              <p *ngIf="mediaService.isMobile()">
                <span  class="classic-link"(click)="openShare()">Link kopieren, um zu einem anderen Gerät zu senden.</span><br>
                <span style="color: #42a5f5; font-style: italic; font-size: 14px"><i class="fa fa-info fam"></i>Wir haben den Link zum Webinar gestern Abend auch an deine Email geschickt!</span>
              </p>
              <p  *ngIf="!mediaService.isMobile() && !webinarIsJoinable" style="background: #e8e8e8;padding:8px">
                <span>Lege dir doch ein Lesezeichen an, um später zurückzufinden </span>
              </p>
              <div *ngIf="!webinarIsJoinable">
                <p style="font-weight: bold">15 Minuten vor Beginn ist der Beitritt möglich.</p>
                <div class="timer" *ngIf="iteminfo && iteminfo.date" fxLayout="row" fxLayoutAlign="center center"
                     fxLayoutGap="10px">
                  <div fxLayout="column" *ngIf="days != 0" fxLayoutAlign="center center"><h4>{{days}}</h4>
                    <div *ngIf="days == 1">Tag</div>
                    <div *ngIf="days > 1">Tage</div>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center"><h4>{{hours}}</h4>
                    <div *ngIf="hours == 1">Stunde</div>
                    <div *ngIf="hours > 1 || hours == 0">Stunden</div>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center"><h4>{{minutes}}</h4>
                    <div *ngIf="minutes == 1">Minute</div>
                    <div *ngIf="minutes > 1 || minutes == 0">Minuten</div>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center"><h4>{{seconds}}</h4>
                    <div *ngIf="seconds == 1">Sekunde</div>
                    <div *ngIf="seconds > 1 || seconds == 0">Sekunden</div>
                  </div>
                </div>
              </div>


              <div fxLayout="column" fxLayoutAlign="space-evenly end" fxLayoutAlign.lt-sm="center center"
                   fxLayout.lt-sm="column" class="button-wrapper">
                <a
                  (click)="showHintAndNavigateToWebinar()"
                  *ngIf="webinarIsJoinable"
                  class="hid-button primary extra-pudding-for-linda">
                  <span *ngIf="!navigating">Webinar jetzt starten <i class="fa fa-play"></i></span>
                  <span *ngIf="navigating" class="text-green">...Weiterleiten...</span>
                </a>
                <span *ngIf="linkError" class="link-error">Mit dem Zugangslink gibt es ein technisches Problem! Bitte schreib uns per WhatsApp!</span>
                <div fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="center center" fxLayout="row"
                     fxLayout.lt-sm="column" class="support separation-top" *ngIf="webinarIsJoinable">
                  <span>Wenn du ein Problem hast, schreib uns:</span>
                  <button class="hid-button secondary inverse" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>Support
                    kontaktieren
                  </button>
                </div>
              </div>
            </div>


            <!-- Hint Page -->

            <div *ngIf="step == 'delayForHint'" class="itemInfoText height-join">
              <h2>Wir leiten dich weiter</h2>
              <div fxLayout="column" fxLayoutAlign="start start">
                <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="start center">
                  <i class="mdi mdi-check-circle hint-icon"></i>
                  <p class="hint-text">Bitte verwende beim Seminar <b>deinen richtigen Namen</b>.
                </div>

                <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="start center">
                  <i class="mdi mdi-camera hint-icon"></i>
                  <p class="hint-text">Vergiss nicht beim Start des Seminars deine <b>Kamera anzumachen</b>.</p>
                </div>

                <p class="hint-text" style="margin: 5px !important;">Die Weiterleitung funktioniert nicht?</p>
                <p class="hint-text" style="margin: 5px !important;">Klicke hier, um deinem Seminar beizutreten.</p>
                <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
                <a [href]="joinlink"
                   class="hid-button primary extra-pudding-for-linda ita_enter-webinar"
                   target="_blank">
                  Seminar beitreten
                </a>
                </div>
              </div>
            </div>


            <!-- After Join Page -->

            <div *ngIf="step == 'enterAgain'" class="itemInfoText height-join">
              <h2>Webinar beitreten</h2>
              <p>Wir haben dich weitergeleitet. Du kannst dem Webinar erneut beitreten.</p>
              <p>Hier ist dein Link: <a [href]="this.iteminfo.webinarLink" target="_blank">{{iteminfo.webinarLink}}</a></p>

              <div fxLayout="column" fxLayoutAlign="space-evenly end" fxLayoutAlign.lt-sm="center center"
                   fxLayout.lt-sm="column" class="button-wrapper">
                <a [href]="joinlink"
                   class="hid-button primary extra-pudding-for-linda"
                   [ngClass]="{'ita_enter-webinar': tripleOptInAuthSucceeded, 'ita_tripleOptIn-authentication': !tripleOptInAuthSucceeded}"
                   target="_blank">
                  Erneut beitreten
                </a>

              </div>
              <div fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="center center" fxLayout="row"
                   fxLayout.lt-sm="column" class="support separation-top" *ngIf="webinarIsJoinable">
                <span>Wenn du ein Problem hast, schreib uns:</span>
                <button class="hid-button secondary inverse" (click)="openSupportModal()"><i class="fa fa-question-mark"></i>Support
                  kontaktieren
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</hid-loading-spinner>
</div>
</div>
