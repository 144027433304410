import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, DownloadTokenInfoData, ValidatePhoneNumberInfoData, ValidatePhoneNumberProcessData, EnterOneClickSignupData, SignupCodeFeedbackData, UpdateRegistrationStateFeedbackData, TokenState} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.ActionLinkAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class ActionLinkAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/actions/updateRegistrationState/cancel',
        endpoint: 'cronos'
    })
    private _cancelRegistration: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {}, UpdateRegistrationStateFeedbackData>;
    cancelRegistration(requestBody: JsonWrappedValue<string>): Promise<UpdateRegistrationStateFeedbackData> {
        return this._cancelRegistration(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/actions/updateRegistrationState/confirm',
        endpoint: 'cronos'
    })
    private _confirmRegistration: IResourceMethodObservableStrict<JsonWrappedValue<string>, {}, {}, UpdateRegistrationStateFeedbackData>;
    confirmRegistration(requestBody: JsonWrappedValue<string>): Promise<UpdateRegistrationStateFeedbackData> {
        return this._confirmRegistration(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/actions/enterOneClickSignup',
        endpoint: 'cronos'
    })
    private _enterPromotionByOneClickSignup: IResourceMethodObservableStrict<EnterOneClickSignupData, {}, {}, TokenState>;
    enterPromotionByOneClickSignup(requestBody: EnterOneClickSignupData): Promise<TokenState> {
        return this._enterPromotionByOneClickSignup(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/actions/enterToken',
        endpoint: 'cronos'
    })
    private _getEnterPromotionTokenInfo: IResourceMethodObservableStrict<void, {code: string}, {}, SignupCodeFeedbackData>;
    getEnterPromotionTokenInfo(requestParams: {code: string}): Promise<SignupCodeFeedbackData> {
        return this._getEnterPromotionTokenInfo(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/download/{code}/content',
        endpoint: 'cronos'
    })
    private _getGiveawayContent: IResourceMethodObservableStrict<void, {}, {code: string}, Blob>;
    getGiveawayContent(code: string): Promise<Blob> {
        return this._getGiveawayContent(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/download/{code}/info',
        endpoint: 'cronos'
    })
    private _getGiveawayInfo: IResourceMethodObservableStrict<void, {}, {code: string}, DownloadTokenInfoData>;
    getGiveawayInfo(code: string): Promise<DownloadTokenInfoData> {
        return this._getGiveawayInfo(null, null, {code: code}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/actions/promotioninfo',
        endpoint: 'cronos'
    })
    private _getValidationInfoByCode: IResourceMethodObservableStrict<void, {code: string}, {}, ValidatePhoneNumberInfoData>;
    getValidationInfoByCode(requestParams: {code: string}): Promise<ValidatePhoneNumberInfoData> {
        return this._getValidationInfoByCode(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/actios/validatePhoneNumber/assignToProm',
        endpoint: 'cronos'
    })
    private _validatePhoneNumberByAssignToPromotionCode: IResourceMethodObservableStrict<void, {code: string, authCode: string}, {}, ValidatePhoneNumberProcessData>;
    validatePhoneNumberByAssignToPromotionCode(requestParams: {code: string, authCode: string}): Promise<ValidatePhoneNumberProcessData> {
        return this._validatePhoneNumberByAssignToPromotionCode(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/actions/validatePhoneNumber/promRegState',
        endpoint: 'cronos'
    })
    private _validatePhoneNumberByPromRegStateCode: IResourceMethodObservableStrict<void, {code: string, authCode: string}, {}, ValidatePhoneNumberProcessData>;
    validatePhoneNumberByPromRegStateCode(requestParams: {code: string, authCode: string}): Promise<ValidatePhoneNumberProcessData> {
        return this._validatePhoneNumberByPromRegStateCode(null, requestParams, null).toPromise()
    }

}