import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AEC1CheckoutEntryComponent } from './aec1-checkout-entry/aec1-checkout-entry.component';
import { AEC1CheckoutMainComponent } from './aec1-checkout-main/aec1-checkout-main.component';
import { AEC1CheckoutLocationComponent } from './aec1-checkout-location/aec1-checkout-location.component';
import { AEC1CheckoutUserComponent } from './aec1-checkout-user/aec1-checkout-user.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {ExtendedModule, FlexModule} from "@angular/flex-layout";
import {
  GlobalCheckoutComponentsModule
} from "../../components/global-checkout-components/global-checkout-components.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HidInteractionsModule} from "../../../hid-interactions/hid-interactions.module";

const appRoutes: Routes = [
  {path: 'angebote/:routingUrl/checkout/aec/aec1/entry', component: AEC1CheckoutEntryComponent},
  {
    path: 'angebote/:routingUrl/checkout/aec/aec1', component: AEC1CheckoutMainComponent,
    children: [
      {path: 'location', component: AEC1CheckoutLocationComponent},
      {path: 'user', component: AEC1CheckoutUserComponent}
    ]
  }
]

@NgModule({
  declarations: [
    AEC1CheckoutEntryComponent,
    AEC1CheckoutMainComponent,
    AEC1CheckoutLocationComponent,
    AEC1CheckoutUserComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(appRoutes),
        BrowserModule,
        FlexModule,
        GlobalCheckoutComponentsModule,
        ExtendedModule,
        FormsModule,
        HidInteractionsModule,
        ReactiveFormsModule
    ]
})
export class AECDiscCheckoutAEC1Module { }
