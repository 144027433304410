import {Component, Inject, OnInit} from '@angular/core';
import {PromotionApiResourceV2, SeminarProcessAPIResourceV2} from "../../../../generated/cronos/resources";
import {ActivatedRoute, Router} from "@angular/router";
import {
  ContactSupportModalComponent
} from "../../../../contact-support-modal/contact-support-modal/contact-support-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PublicOfferPageResource} from "../../../../generated/cms/resources";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {saveAs} from "file-saver";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RegistrationInfoData} from "../../../../generated/cronos/data";
import {RegistrationInfoService} from "../../registration-info.service";
import {DateUtilsService} from "../../../../inno-utils/dateutils.service";

@Component({
  selector: 'app-download-seminar-handout',
  templateUrl: './download-seminar-handout.component.html',
  styleUrls: ['./download-seminar-handout.component.scss']
})
export class DownloadSeminarHandoutComponent implements OnInit {

  handoutDownloaded: boolean = false;
  certificatedownloaded: boolean = false;
  emailSend: boolean = false;
  actualDate: Date = new Date();
  afterSeminar: boolean = false;

  recommendedUpsellPageIds: number[] = [];

  token: string = '';
  registrationInfo: RegistrationInfoData = <RegistrationInfoData>{};
  seminarFeedbackPageDataLoading: boolean = true;

  permissionToDownloadCertificate: boolean = false;

  constructor(
    private http: HttpClient,
    private seminarProcessAPIResourceV2: SeminarProcessAPIResourceV2,
    private promotionResource: PromotionApiResourceV2,
    private offerPageResource: PublicOfferPageResource,
    private route: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    public snackBar: MatSnackBar,
    private registrationInfoService: RegistrationInfoService,
    private promotionApiResource: PromotionApiResourceV2,
    @Inject("env") private environment,
    public dateUtils: DateUtilsService
  ) {
  }

  ngOnInit() {
    this.getTokenInformations();
  }

  getTokenInformations() {
    this.seminarFeedbackPageDataLoading = true;
    this.token = this.route.snapshot.queryParamMap.get("code");
    this.registrationInfoService.getRegistrationInfoForToken(this.token).then(result => {
      this.registrationInfo = result;
      this.seminarFeedbackPageDataLoading = false;

      if (this.registrationInfo.promotionData) {
        this.getRecommendedOfferPageIds();
        this.compareDates()
        this.checkPermissionToDownloadPromRegStateRelatedCertificate()
      }
    })
  }

  downloadHandout() {
    this.http.get(this.environment.cronosApiUrl + '/apiv2/promotions/seminars/download/' + this.token, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe(
      response => {
        let filename = "Handout-Seminar.pdf"
        try{
          filename = response.headers.get("content-disposition").replace(/^attachment; filename=/, "")
        }catch (e) {
//error maybe thrown due to case sensitivity?
        }
        saveAs(response.body, filename)
        this.handoutDownloaded = true
      }
    )
  }
  sendHandoutToEmail() {
    this.seminarProcessAPIResourceV2.sendSeminarMaterialByEmailToContactByToken(this.token).then(result => {
      if (result.state == 'VALID') {
        this.snackBar.open('E-Mail an ' + this.registrationInfo.contactData.email + ' versendet!', null, {
          duration: 3000
        });
        this.emailSend = true;
      }
    })
  }
  checkPermissionToDownloadPromRegStateRelatedCertificate() {
    this.promotionApiResource.checkPermissionToDownloadPromRegStateRelatedCertificate(this.token).then(
      result => {
        this.permissionToDownloadCertificate = result.value
      }
    )
  }

  goHome() {
    this.router.navigate(["home"], {queryParamsHandling: 'preserve'})
  }

  openSupportModal() {
    this.modal.open(ContactSupportModalComponent);
  }

  getRecommendedOfferPageIds() {
    this.offerPageResource.getOfferPageUpsellRecommendationsBySyncedFormatId(this.registrationInfo.promotionData.formatId).then(res => {
      this.recommendedUpsellPageIds = res;
    })
  }

  compareDates() {
    let newDate: Date = new Date(this.registrationInfo.promotionData.date)
    //3*60*60*1000 are milliseconds and its equal to 3 hours. return true if the actualDate ist 3 hours after seminar start
    this.afterSeminar = this.actualDate.getTime() > (newDate.getTime() + 3 * 60 * 60 * 1000);
  }

}
