import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeminarCheckoutGatewayComponent} from './seminar-checkout-gateway/seminar-checkout-gateway.component';
import {RouterModule, Routes} from '@angular/router';
import {ClickOutsideDirective} from './click-outside.directive';
import {SemCheckoutFlavourModule} from './seminar-checkout-flavour-text/sem-checkout-flavour.module';
import {SeminarCheckoutS5Module} from './seminar-checkout-s5/seminar-checkout-s5.module';


const appRoutes: Routes = [{path: 'angebote/:routingUrl/checkout/seminars/g', component: SeminarCheckoutGatewayComponent}];

@NgModule({
  declarations: [
    SeminarCheckoutGatewayComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    SemCheckoutFlavourModule,
    SeminarCheckoutS5Module,
    RouterModule.forChild(appRoutes)
  ]
})
export class SeminarCheckoutModule { }
