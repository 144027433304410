import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue, PublicCouponData, EnterPromotionData, OnlineLectureAccessTokenData, CreateRatingBasedFeedbackData, PublicSeminarData, PublicWebinarDateData, CreateReasonBasedPerfectMatchFeedbackData, PagedData, FeedbackDisplayData, FeedBackOverviewData, BuddySignUpData, PublicPromotionData, RegistrationInfoData, TalentagentRegistrationData, PublicPromotionFormatData, CreateReasonBasedSeminarFeedbackData, WebinarJoinInfoData} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.PromotionApiControllerV2
 */

@Injectable()
@ResourceParams({})
export class PromotionApiResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/webinars/dates',
        endpoint: 'cronos'
    })
    private _checkForImminentWebinarDate: IResourceMethodObservableStrict<void, {promotionIds: number[]}, {}, PublicWebinarDateData>;
    checkForImminentWebinarDate(requestParams: {promotionIds: number[]}): Promise<PublicWebinarDateData> {
        return this._checkForImminentWebinarDate(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/certificate/permission/{token}',
        endpoint: 'cronos'
    })
    private _checkPermissionToDownloadPromRegStateRelatedCertificate: IResourceMethodObservableStrict<void, {}, {token: string}, JsonWrappedValue<boolean>>;
    checkPermissionToDownloadPromRegStateRelatedCertificate(token: string): Promise<JsonWrappedValue<boolean>> {
        return this._checkPermissionToDownloadPromRegStateRelatedCertificate(null, null, {token: token}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/certificate/download/{token}',
        endpoint: 'cronos'
    })
    private _downloadPromRegStateRelatedCertificate: IResourceMethodObservableStrict<void, {}, {token: string}, Blob>;
    downloadPromRegStateRelatedCertificate(token: string): Promise<Blob> {
        return this._downloadPromRegStateRelatedCertificate(null, null, {token: token}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/events/{promotionId}/enter',
        endpoint: 'cronos'
    })
    private _enterEventByPromotionId: IResourceMethodObservableStrict<EnterPromotionData, {}, {promotionId: number}, JsonWrappedValue<string>>;
    enterEventByPromotionId(requestBody: EnterPromotionData, promotionId: number): Promise<JsonWrappedValue<string>> {
        return this._enterEventByPromotionId(requestBody, null, {promotionId: promotionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/onlineLectures/{formatId}/enter',
        endpoint: 'cronos'
    })
    private _enterOnlineLectureByFormatId: IResourceMethodObservableStrict<EnterPromotionData, {}, {formatId: number}, OnlineLectureAccessTokenData>;
    enterOnlineLectureByFormatId(requestBody: EnterPromotionData, formatId: number): Promise<OnlineLectureAccessTokenData> {
        return this._enterOnlineLectureByFormatId(requestBody, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/{formatId}/enter',
        endpoint: 'cronos'
    })
    private _enterPromotionByFormatId: IResourceMethodObservableStrict<EnterPromotionData, {}, {formatId: number}, JsonWrappedValue<string>>;
    enterPromotionByFormatId(requestBody: EnterPromotionData, formatId: number): Promise<JsonWrappedValue<string>> {
        return this._enterPromotionByFormatId(requestBody, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/{formatId}/talentagentApp/enter',
        endpoint: 'cronos'
    })
    private _enterPromotionByFormatIdForTalentagent: IResourceMethodObservableStrict<EnterPromotionData, {}, {formatId: number}, JsonWrappedValue<boolean>>;
    enterPromotionByFormatIdForTalentagent(requestBody: EnterPromotionData, formatId: number): Promise<JsonWrappedValue<boolean>> {
        return this._enterPromotionByFormatIdForTalentagent(requestBody, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/promotion/{promotionId}/talentagentApp/enter',
        endpoint: 'cronos'
    })
    private _enterPromotionWithNewContactDataForTalentagent: IResourceMethodObservableStrict<EnterPromotionData, {}, {promotionId: number}, JsonWrappedValue<boolean>>;
    enterPromotionWithNewContactDataForTalentagent(requestBody: EnterPromotionData, promotionId: number): Promise<JsonWrappedValue<boolean>> {
        return this._enterPromotionWithNewContactDataForTalentagent(requestBody, null, {promotionId: promotionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/feedback/rating/{feedbackToken}',
        endpoint: 'cronos'
    })
    private _enterRatingBasedFeedback: IResourceMethodObservableStrict<CreateRatingBasedFeedbackData, {}, {feedbackToken: string}, void>;
    enterRatingBasedFeedback(requestBody: CreateRatingBasedFeedbackData, feedbackToken: string): Promise<void> {
        return this._enterRatingBasedFeedback(requestBody, null, {feedbackToken: feedbackToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/feedback/perfectMatch/reason/{feedbackToken}',
        endpoint: 'cronos'
    })
    private _enterReasonBasedPerfectMatchFeedback: IResourceMethodObservableStrict<CreateReasonBasedPerfectMatchFeedbackData, {}, {feedbackToken: string}, void>;
    enterReasonBasedPerfectMatchFeedback(requestBody: CreateReasonBasedPerfectMatchFeedbackData, feedbackToken: string): Promise<void> {
        return this._enterReasonBasedPerfectMatchFeedback(requestBody, null, {feedbackToken: feedbackToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/apiv2/promotions/feedback/seminar/reason/{feedbackToken}',
        endpoint: 'cronos'
    })
    private _enterReasonBasedSeminarFeedback: IResourceMethodObservableStrict<CreateReasonBasedSeminarFeedbackData, {}, {feedbackToken: string}, void>;
    enterReasonBasedSeminarFeedback(requestBody: CreateReasonBasedSeminarFeedbackData, feedbackToken: string): Promise<void> {
        return this._enterReasonBasedSeminarFeedback(requestBody, null, {feedbackToken: feedbackToken}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/seminars/{seminarId}/enter',
        endpoint: 'cronos'
    })
    private _enterSeminarWithNewContactData: IResourceMethodObservableStrict<EnterPromotionData, {}, {seminarId: number}, JsonWrappedValue<string>>;
    enterSeminarWithNewContactData(requestBody: EnterPromotionData, seminarId: number): Promise<JsonWrappedValue<string>> {
        return this._enterSeminarWithNewContactData(requestBody, null, {seminarId: seminarId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/{fid}/active',
        endpoint: 'cronos'
    })
    private _getActivePromotionOfFormat: IResourceMethodObservableStrict<void, {}, {fid: number}, PublicPromotionData>;
    getActivePromotionOfFormat(fid: number): Promise<PublicPromotionData> {
        return this._getActivePromotionOfFormat(null, null, {fid: fid}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/seminars/multipleRegistrations',
        endpoint: 'cronos'
    })
    private _getAllowanceForMultipleRegistrationsForLocationAndSeminar: IResourceMethodObservableStrict<void, {locationId: number, formatId: number}, {}, JsonWrappedValue<boolean>>;
    getAllowanceForMultipleRegistrationsForLocationAndSeminar(requestParams: {locationId: number, formatId: number}): Promise<JsonWrappedValue<boolean>> {
        return this._getAllowanceForMultipleRegistrationsForLocationAndSeminar(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/events/{formatId}/available',
        endpoint: 'cronos'
    })
    private _getAvailablePromotionCountForEventFormat: IResourceMethodObservableStrict<void, {}, {formatId: number}, JsonWrappedValue<number>>;
    getAvailablePromotionCountForEventFormat(formatId: number): Promise<JsonWrappedValue<number>> {
        return this._getAvailablePromotionCountForEventFormat(null, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/coupons/{couponId}/code',
        endpoint: 'cronos'
    })
    private _getCodeForSpecificCoupon: IResourceMethodObservableStrict<void, {}, {couponId: number}, JsonWrappedValue<string>>;
    getCodeForSpecificCoupon(couponId: number): Promise<JsonWrappedValue<string>> {
        return this._getCodeForSpecificCoupon(null, null, {couponId: couponId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/events',
        endpoint: 'cronos'
    })
    private _getEvents: IResourceMethodObservableStrict<void, {locationId?: number, formatIds: number[], page?: number, pageSize?: number}, {}, PublicPromotionData[]>;
    getEvents(requestParams: {locationId?: number, formatIds: number[], page?: number, pageSize?: number}): Promise<PublicPromotionData[]> {
        return this._getEvents(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/attachments/ics/{cookieIdentificationCode}',
        endpoint: 'cronos'
    })
    private _getIcsAttachmentByToken: IResourceMethodObservableStrict<void, {}, {cookieIdentificationCode: string}, Blob>;
    getIcsAttachmentByToken(cookieIdentificationCode: string): Promise<Blob> {
        return this._getIcsAttachmentByToken(null, null, {cookieIdentificationCode: cookieIdentificationCode}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/seminars/local',
        endpoint: 'cronos'
    })
    private _getLocalSeminarsByToken: IResourceMethodObservableStrict<void, {token: string, numberOfSeminars?: number}, {}, PublicSeminarData[]>;
    getLocalSeminarsByToken(requestParams: {token: string, numberOfSeminars?: number}): Promise<PublicSeminarData[]> {
        return this._getLocalSeminarsByToken(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/seminars/local/preview',
        endpoint: 'cronos'
    })
    private _getLocalSeminarsForPreview: IResourceMethodObservableStrict<void, {promotionId: number, numberOfSeminars?: number}, {}, PublicSeminarData[]>;
    getLocalSeminarsForPreview(requestParams: {promotionId: number, numberOfSeminars?: number}): Promise<PublicSeminarData[]> {
        return this._getLocalSeminarsForPreview(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/feedback/format/{formatId}',
        endpoint: 'cronos'
    })
    private _getPagedRatingBasedFeedbackByFormatType: IResourceMethodObservableStrict<void, {page?: number, pageSize?: number}, {formatId: number}, PagedData<FeedbackDisplayData>>;
    getPagedRatingBasedFeedbackByFormatType(formatId: number, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<FeedbackDisplayData>> {
        return this._getPagedRatingBasedFeedbackByFormatType(null, requestParams, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/webinars/promRegstate/byCookie',
        endpoint: 'cronos'
    })
    private _getPromRegStateCodeByCookieIdent: IResourceMethodObservableStrict<void, {cookieIdent: string}, {}, JsonWrappedValue<string>>;
    getPromRegStateCodeByCookieIdent(requestParams: {cookieIdent: string}): Promise<JsonWrappedValue<string>> {
        return this._getPromRegStateCodeByCookieIdent(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/formats/{id}',
        endpoint: 'cronos'
    })
    private _getPromotionFormat: IResourceMethodObservableStrict<void, {}, {id: number}, PublicPromotionFormatData>;
    getPromotionFormat(id: number): Promise<PublicPromotionFormatData> {
        return this._getPromotionFormat(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/formats/{id}',
        endpoint: 'cronos'
    })
    private _getPromotionFormatInfos: IResourceMethodObservableStrict<void, {}, {id: number}, PublicPromotionFormatData>;
    getPromotionFormatInfos(id: number): Promise<PublicPromotionFormatData> {
        return this._getPromotionFormatInfos(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/formats',
        endpoint: 'cronos'
    })
    private _getPromotionFormats: IResourceMethodObservableStrict<void, {token: string}, {}, any>;
    getPromotionFormats(requestParams: {token: string}): Promise<any> {
        return this._getPromotionFormats(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/formats/sync',
        endpoint: 'cronos'
    })
    private _getPromotionFormatsForSync: IResourceMethodObservableStrict<void, {token: string}, {}, any>;
    getPromotionFormatsForSync(requestParams: {token: string}): Promise<any> {
        return this._getPromotionFormatsForSync(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/{id}',
        endpoint: 'cronos'
    })
    private _getPromotionInfos: IResourceMethodObservableStrict<void, {}, {id: number}, PublicPromotionData>;
    getPromotionInfos(id: number): Promise<PublicPromotionData> {
        return this._getPromotionInfos(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/coupons/currentWeek',
        endpoint: 'cronos'
    })
    private _getPublicCouponsForCurrentWeek: IResourceMethodObservableStrict<void, {}, {}, PublicCouponData[]>;
    getPublicCouponsForCurrentWeek(): Promise<PublicCouponData[]> {
        return this._getPublicCouponsForCurrentWeek(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/formats/ratingsummary',
        endpoint: 'cronos'
    })
    private _getRatingSummary: IResourceMethodObservableStrict<void, {formatIds: number[]}, {}, FeedBackOverviewData>;
    getRatingSummary(requestParams: {formatIds: number[]}): Promise<FeedBackOverviewData> {
        return this._getRatingSummary(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/ratings',
        endpoint: 'cronos'
    })
    private _getRatingsByPromotionType: IResourceMethodObservableStrict<void, {formatIds?: number[], rating?: number, filterForCommentExists?: boolean, page?: number, pageSize?: number}, {}, PagedData<FeedbackDisplayData>>;
    getRatingsByPromotionType(requestParams?: {formatIds?: number[], rating?: number, filterForCommentExists?: boolean, page?: number, pageSize?: number}): Promise<PagedData<FeedbackDisplayData>> {
        return this._getRatingsByPromotionType(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/registration/{token}/info',
        endpoint: 'cronos'
    })
    private _getRegistrationInfo: IResourceMethodObservableStrict<void, {}, {token: string}, RegistrationInfoData>;
    getRegistrationInfo(token: string): Promise<RegistrationInfoData> {
        return this._getRegistrationInfo(null, null, {token: token}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/promotion/{promotionId}/registrations',
        endpoint: 'cronos'
    })
    private _getRegistrationsByPromotionIdByTalentagent: IResourceMethodObservableStrict<void, {}, {promotionId: number}, TalentagentRegistrationData[]>;
    getRegistrationsByPromotionIdByTalentagent(promotionId: number): Promise<TalentagentRegistrationData[]> {
        return this._getRegistrationsByPromotionIdByTalentagent(null, null, {promotionId: promotionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/seminars/{id}',
        endpoint: 'cronos'
    })
    private _getSeminarDateInfos: IResourceMethodObservableStrict<void, {}, {id: number}, PublicSeminarData>;
    getSeminarDateInfos(id: number): Promise<PublicSeminarData> {
        return this._getSeminarDateInfos(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/seminars/fallback',
        endpoint: 'cronos'
    })
    private _getSeminarFallbackConfiguration: IResourceMethodObservableStrict<void, {locationId: number, formatId: number}, {}, JsonWrappedValue<('Video' | 'WaitingList')>>;
    getSeminarFallbackConfiguration(requestParams: {locationId: number, formatId: number}): Promise<JsonWrappedValue<('Video' | 'WaitingList')>> {
        return this._getSeminarFallbackConfiguration(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/seminars',
        endpoint: 'cronos'
    })
    private _getSeminars: IResourceMethodObservableStrict<void, {locationId: number, formatIds: number[], page?: number, pageSize?: number}, {}, PublicSeminarData[]>;
    getSeminars(requestParams: {locationId: number, formatIds: number[], page?: number, pageSize?: number}): Promise<PublicSeminarData[]> {
        return this._getSeminars(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/promotions/webinar/enter',
        endpoint: 'cronos'
    })
    private _getWebinarInfosByToken: IResourceMethodObservableStrict<void, {code: string}, {}, WebinarJoinInfoData>;
    getWebinarInfosByToken(requestParams: {code: string}): Promise<WebinarJoinInfoData> {
        return this._getWebinarInfosByToken(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/apiv2/promotions/seminars/{seminarId}/signupBuddies',
        endpoint: 'cronos'
    })
    private _signUpBuddiesForSeminar: IResourceMethodObservableStrict<BuddySignUpData, {}, {seminarId: number}, void>;
    signUpBuddiesForSeminar(requestBody: BuddySignUpData, seminarId: number): Promise<void> {
        return this._signUpBuddiesForSeminar(requestBody, null, {seminarId: seminarId}).toPromise()
    }

}