import {Component, Input, OnInit} from '@angular/core';
import {MediaService} from "../../media.service";

@Component({
  selector: 'app-hid-to-talentagent-banner',
  templateUrl: './hid-to-talentagent-banner.component.html',
  styleUrls: ['./hid-to-talentagent-banner.component.scss']
})
export class HidToTalentagentBannerComponent implements OnInit {

  @Input() utmCampaign: string = ""
  @Input() formatId: number = 0

  link: string = ""

  constructor(
    public mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.buildRedirectToTalentagentLink()
  }

  buildRedirectToTalentagentLink() {
    this.link = `https://talentagent.de/talent?utm_source=hidpage&utm_medium=actionpage-ta-banner&utm_campaign=${this.utmCampaign}&utm_content=${this.formatId}`
  }

}
