import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-aecdisc-checkout-gateway',
  templateUrl: './aecdisc-checkout-gateway.component.html',
  styleUrls: ['./aecdisc-checkout-gateway.component.scss']
})
export class AECDiscCheckoutGatewayComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.proceed()
  }

  proceed() {
    let offerUrl = (this.route.params as BehaviorSubject<any>).value['routingUrl'];
    let random = Math.floor(Math.random() * 2);
    let url = 'angebote/' + offerUrl + '/checkout/aec/aec1/entry';
    this.router.navigate([url], {queryParamsHandling: 'preserve'});
  }

}
