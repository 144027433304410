<hid-loading-spinner [loadingBool]="registrationInfoLoading">
<!-- Feedback not already given -->
<div *ngIf="!registrationInfoLoading &&  registrationInfo.feedbackEntered != true"
     id="feedback-main-wrapper"
     class="ita-format-{{registrationInfo.promotionData.formatId}}" fxLayout="column"
     fxLayoutAlign="start center">
  <div id="logo">
    🎉
    <!--img src="../../../../../assets/img/likeSmiley.jpg"-->
  </div>

  <h1>Hey, wie war dein Seminar?</h1>

  <app-promotion-info-box class="w-100" [promotionData]="registrationInfo.promotionData"></app-promotion-info-box>

  <h3>Um unser Angebot zu verbessern, brauchen wir <b>dein Feedback</b>. Wie bewertest du das Seminar?</h3>

  <div id="rating-box">
    <div id="stars">
      <div class="rating-good">nicht gut</div>
      <hid-star-rating size="50px" [model]="0" (valueChange)="updateFormControlValue($event)"></hid-star-rating>
      <div class="rating-bad">super gut</div>
    </div>

    <div *ngIf="feedbackGroup.controls.rating.value < 4 && feedbackGroup.controls.rating.value > 0" class="reason-box" fxLayout="column" fxLayoutAlign="start center">
      <div class="reason-title">Danke für dein Feedback!<br>Kannst du uns einen genaueren Grund für deine Bewertung geben? </div>
      <div class="reason-chips">
        <div *ngFor="let reason of badFeedbackReasons"
             [class.selected]="isSelectedReason(reason)"
             (click)="onReasonChipClick(reason)"
             class="chip">
          {{reason | badFeedbackReason}}
        </div>
      </div>
    </div>

    <div class="text-area">
      <hid-textarea
        label="Kommentar (optional)"
        minRows="5"
        maxLength="500"
        charCounterShow="true"
        [control]="feedbackGroup.controls['reasonText']"></hid-textarea>
    </div>

    <app-anonymize-feedback [anonymizeControl]="feedbackGroup.controls['anonymized']"></app-anonymize-feedback>

    <div class="student-was-not-present" fxLayout="column" fxLayoutAlign="start center">
      <div class="link" (click)="navigateToStudentWasNotPresent()">Ich war nicht beim Seminar.</div>
    </div>

    <div id="action-box" fxLayout="column" fxLayoutAlign="center end">
      <div *ngIf="errorMessage && !(feedbackGroup.controls.rating.value < 6 && feedbackGroup.controls.rating.value > 0)"
           class="error-message">Bitte gib erst eine Bewertung ab!
      </div>

      <button [disabled]="feedbackSent" class="hid-button primary ita-send-feedback-present-btn"
              (click)="sendFeedback()">Feedback senden
      </button>
    </div>
  </div>
</div>

<!-- Feedback was already given -->
<div class="actionlink-page-wrapper" *ngIf="registrationInfo?.feedbackEntered == true" fxLayout="column" fxLayoutAlign="start center">
<div class="actionlink-main-box">
  <div id="logo"style="text-align: center;">
    😕
    <!--img src="../../../../../assets/img/wrongSmiley.jpg"-->
  </div>

  <div class="explanation">Du hast schon Feedback zu deinem Seminar abgegeben. Vielen Dank!
  </div>

  <app-promotion-info-box [promotionData]="registrationInfo.promotionData"></app-promotion-info-box>

  <div fxLayout="row" fxLayoutAlign="center center">
  <button class="hid-button secondary inverse" (click)="goHome()">Zur Startseite</button>
  </div>
</div>
</div>

  <div class="hid-to-ta-wrapper" *ngIf="registrationInfo?.promotionData?.formatId && dateUtils.isWithinNextFullYear(registrationInfo?.contactData?.studyEndYear)">
    <app-hid-to-talentagent-banner [utmCampaign]="'feedback-student-present-feedback'"
                                   [formatId]="registrationInfo?.promotionData?.formatId">
    </app-hid-to-talentagent-banner>
  </div>

  <div fxLayout="column" fxLayoutAlign="start center"
       *ngIf="recommendedUpsellPageIds.length > 0 && !dateUtils.isWithinNextFullYear(registrationInfo?.contactData?.studyEndYear)">

    <h1>Das könnte dich auch interessieren:</h1>
    <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
      <app-offer-page-panel
        [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel'}"
        [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
      </app-offer-page-panel>
    </div>
  </div>

  <div *ngIf="recommendedUpsellPageIds.length == 0 && !dateUtils.isWithinNextFullYear(registrationInfo?.contactData?.studyEndYear)" fxLayout="column"
       fxLayoutAlign="center center" id="to-main-page">
    <div class="explanation">Wir haben noch andere spannende Seminare und Aktionen für dich!</div>
    <button class="hid-button secondary" (click)="goHome()">Weiterstöbern</button>
  </div>

</hid-loading-spinner>



<!-- Page bottom (upsells) -->
