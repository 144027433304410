<div id="checkout-header" fxLayout="row" fxLayoutAlign="space-between center" style="font-size: 20px">

  <h3><strong>Passende Termine für dich</strong></h3>
  <button class="hid-icon-button inverse ita_close_checkout-modal" (click)="globalCheckoutService.returnToOfferPage()">
    <i
      class="fas fa-times"></i>
  </button>
</div>

<div class="s4-checkout-body">
  <div class="date-list">
    <hid-loading-spinner [loadingBool]="loading">

      <app-checkout-seminardate-segmentation *ngIf="dates.length > 0" [seminarDates]="dates"
                                             [selectedDateSubject]="promotionSubject"
                                             [displayedSeminarAmountSubject]="totalDisplayedSeminarsAmountSubject"></app-checkout-seminardate-segmentation>


      <div *ngIf="offerWaitinglist" class="no-dates-container">
        <i class="mdi mdi-calendar-remove-outline"></i>
        <div class="w-100" style="margin-top: 10px;text-align: center" fxLayout="row"
             fxLayout.xs="column" fxLayoutAlign="center center">
          <div style="margin-right: 5px;">Keinen Platz gefunden?</div>
          <div> Jetzt in die <span class="link-button" (click)="enableWaitingList()">Warteliste</span> eintragen.</div>
        </div>
      </div>

    </hid-loading-spinner>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button class="hid-button inverse secondary ita_step-back" (click)="back()">Zurück</button>
  </div>
</div>
