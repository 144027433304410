<div id="header" [className]="mainTrackingClassString">
  <div id="header-content" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign.lt-sm="center center" fxLayoutAlign="center center"
         class="isu-padding title-container">
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
      <p *ngIf="signUpInfo.promotionType != 'OnlineLectureFormat' && signUpInfo.promotionType != 'GiveawayFormat'">
        Anmeldung für {{signUpInfo.promotionTitle}} erfolgreich!</p>
      <p *ngIf="signUpInfo.promotionType != 'OnlineLectureFormat' && signUpInfo.promotionType == 'GiveawayFormat'">
        Anmeldung für {{signUpInfo.promotionTitle}} erfolgreich! Wir haben dir den Download per E-Mail
        geschickt.</p>
      <p *ngIf="signUpInfo.promotionType === 'OnlineLectureFormat'">Dein Online-Seminar</p>
    </div>

  </div>
  <!--test-->
</div>

<div class="width-100 isu-padding-8x" fxLayout="row" fxLayoutAlign="center center">
  <div class="body-content">
    <!-- nächste Schritte für AECDisc Ty-Page sollen oberhald des "mit freunden Teilen" Feld sein-->
    <div class="next-steps-container" *ngIf="cronosFormatId == 2324">
      <div class="next-steps">
        <h3><strong>Die nächsten Schritte: </strong></h3>
        <div class="next-step-item">
          <div>📬</div>
          <div>Checke deine <strong>Mails</strong> für den <strong>Testlink</strong> zur
            {{signUpInfo.promotionTitle}}.</div>
        </div>
        <div class="next-step-item">
          <div>👩‍💻</div>
          <div>Wir suchen nach einem <strong>Experten,</strong> der deine Testergebnisse gemeinsam mit dir
            auswerten wird.
          </div>
        </div>
      </div>
    </div>


    <div class="share-container" [ngClass]="{'aecdisc-share-container':cronosFormatId == 2324}">
      <div class="share-description" *ngIf="cronosFormatId == 2324">
        <h3 class="share-heading">Deine Kommilitonen würden es sicher auch lieben!</h3>
        <div class="share-sub-heading">Lade sie zur {{signUpInfo.promotionTitle}} ein</div>
      </div>
      <div class="share-description" *ngIf="this.signUpInfo.promotionType === 'WebinarFormat'">
        <h3 class="share-heading" >Gemeinsam macht's mehr Spaß!🤝</h3>
        <div class="share-sub-heading">Lade deine Kommilitonen zum Webinar ein</div>
      </div>
      <div class="share-description" *ngIf="signUpInfo.promotionType == 'GiveawayFormat'">
        <h3 class="share-heading">Kann auch deinen Freunden helfen!</h3>
        <div class="share-sub-heading">Teile das Material mit deinen Kommilitonen</div>
      </div>
      <button class="share-button ita_initiate-share" (click)="openShareMenue()"><i class="fa fa-share"
                                                                                    style="margin-right: 10px"></i>
        Mit Freunden teilen
      </button>
    </div>

    <div class="next-steps-container"
         *ngIf="(this.signUpInfo.promotionType === 'WebinarFormat' ||
      this.signUpInfo.promotionType === 'OfflineSeminarFormat' || this.signUpInfo.promotionType === 'EventFormat') && !waitingListSignup"
         [ngClass]="{'mail-height' : this.signUpInfo.promotionType === 'OnlineLectureFormat'}">
      <div class="next-steps">
        <h3><strong>Deine nächsten Schritte: </strong></h3>
        <div class="next-step-item">
          <div>📬</div>
          <div>Checke deine <strong>Mails</strong> für den <strong>Zugangslink</strong> und alle wichtigen Infos.</div>
        </div>
        <div class="next-step-item"
             *ngIf="['OfflineSeminarFormat', 'WebinarFormat'].includes(signUpInfo.promotionType)">
          <div>📆</div>
          <div>Füge das Seminar deinem <strong>Kalender</strong> hinzu:
            <span
            ><br>{{signUpInfo.date | date:'EEEE dd.MM.yyyy HH:mm'}} <br></span>
            <div>
              <button class="ics-date-button" (click)="downloadICSFile()"
                      *ngIf="signUpInfo.promotionType === 'WebinarFormat'">
                <i class="fas fa-download fam" style="margin-right: 10px;"></i>
                ZUM KALENDER HINZUFÜGEN
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div id="waitingListInfo" class="isu-padding-2x next-steps-container" *ngIf="waitingListSignup">
      <h3><b>Deine nächsten Schritte</b></h3>
      <div class="next-step-item">
        <div>📬</div>
        <p>Wir benachrichtigen dich per Mail, wenn es neue Termine zu <b>{{signUpInfo.promotionTitle}}</b> gibt!
        </p>
      </div>
    </div>
  </div>

</div>


<!-- TODO is this banner even used? -->
<!--<div class="talentagent-promo-banner" *ngIf="showTaPromotion">-->
<!--  <div class="display-desktop" style="height: 100%;">-->
<!--    <div style="height: 100%;">-->
<!--      <div fxFlex="60"></div>-->
<!--      <div fxFlex="40" class="talentagent-promo-content" fxLayoutAlign="center start" fxLayout="column">-->
<!--        <h1>Bereit für den Berufseinstieg</h1>-->
<!--        <p>Unsere Karriere-Plattform Talentagent hilft dir dabei den perfekten Job ohne langes suchen zu-->
<!--          finden!</p>-->

<!--        <button class="isu-cta-button ta-cta-button" (click)="navToTalentAgent()"-->
<!--        >Einstiegsjob finden!-->
<!--        </button>-->

<!--        &lt;!&ndash;-->
<!--        <a href="https://app.talentagent.de/onboarding/"><button class="isu-cta-button ta-cta-button"-->
<!--        >Einstiegsjob finden!</button></a>-->
<!--        &ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div style="height: 100%;" class="tilt-overlay display-mobile">-->
<!--    <div class="talentagent-promo-content" fxLayoutAlign="end start" fxLayout="column">-->
<!--      <h1>Bereit für den Berufseinstieg</h1>-->
<!--      <p>Unsere Karriere-Plattform Talentagent hilft dir dabei den perfekten Job ohne langes suchen zu finden!</p>-->
<!--      <button class="isu-cta-button ta-cta-button" (click)="navToTalentAgent()"-->
<!--      >Einstiegsjob finden!-->
<!--      </button>-->

<!--      &lt;!&ndash;-->
<!--      <a href="https://app.talentagent.de/onboarding/"><button class="isu-cta-button ta-cta-button"-->
<!--      >Einstiegsjob finden!</button></a>-->
<!--      &ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div *ngIf="!showTaPromotion">-->
<!--  <br/>-->
<!--  <br/>-->
<!--</div>-->

<div *ngIf="dateUtils.isWithinNextFullYear(userData?.studyEndYear)">
  <app-hid-to-talentagent-banner [utmCampaign]="'seminaranmeldung-thank-you'"
                                 [formatId]="cronosFormatId">
  </app-hid-to-talentagent-banner>
</div>



<div class="content-wrapper" fxLayout="column" fxLayoutAlign="center center">
  <div>
    <div fxLayout="column" fxLayoutAlign="start center" *ngIf="recommendedUpsellPageIds.length > 0 && !dateUtils.isWithinNextFullYear(userData?.studyEndYear)">

      <h1>Das könnte dich auch interessieren:</h1>
      <div fxLayout="row wrap" fxLayoutAlign="center start" style="margin-bottom: 30px;">
        <app-offer-page-panel
          [queryParams]="{utm_source:'hidpage',utm_medium:'thankyoupage', utm_campaign:'thankyoupage-offer-caroussel',
           utm_content: 'formats-id-'+ cronosFormatId}"
          [pageId]="pageId" *ngFor="let pageId of recommendedUpsellPageIds">
        </app-offer-page-panel>
      </div>
    </div>
  </div>
</div>
