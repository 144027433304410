<div  id="checkout-header" fxLayout="row" fxLayoutAlign="space-between center" style="font-size: 20px">
  <span>Gleich geschafft!</span>
  <button fxHide.xs class="hid-icon-button inverse ita_close_checkout-modal" (click)="globalCheckoutService.returnToOfferPage()"><i
    class="fas fa-times"></i>
  </button>
</div>


<div class="checkout-body">
  <hid-loading-spinner [loadingBool]="checkingOut">


    <form [formGroup]="contactForm" fxLayout="column">
      <div class="aecdisc-context-info">
        Nach deiner Anmeldung bekommst du <b>per Mail</b> deinen <b>Link zur Potentialanalyse.</b>
      </div>

      <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="true"
                       inputFieldClass="ita_input_first-name" type="text" name="firstName"
                       [control]="contactForm.controls['firstName']" label="Vorname"></hid-input-field>
      <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="true"
                       inputFieldClass="ita_input_last-name" type="text" name="lastName"
                       [control]="contactForm.controls['lastName']" label="Nachname"></hid-input-field>
      <app-hid-input-phone-number [checkoutAttempted]="checkoutAttempted" [showValidatorIcons]="true"
                                  inputFieldClass="ita_input_phone"
                                  name="phoneNumber" type="tel" [control]="contactForm.controls['phoneNumber']"
                                  label="Telefonnummer"
      ></app-hid-input-phone-number>
      <hid-input-field [forceValidation]="checkoutAttempted" [showValidatorIcons]="true"
                       inputFieldClass="ita_input_email"
                       type="email" name="email" [control]="contactForm.controls['email']" label="Email"></hid-input-field>

      <app-select-study-end-year [control]="contactForm.controls['studyEndYear']"></app-select-study-end-year>

      <div fxLayout="column">


        <hid-checkbox inputClass="ita_check_privacy"
                      style="font-size: 14px; margin-top:20px;"
                      [highlightErrors]="checkoutAttempted"
                      [control]="contactForm.controls['acceptPrivacy']">Ich willige in die Verarbeitung gemäß der Erklärung
          auf der <a [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">Datenschutzsseite</a> ein.
        </hid-checkbox>


        <span class="hid-validationError" style="    margin-left: 65px;"
              *ngIf="checkoutAttempted.value && contactForm.controls['acceptPrivacy'].invalid">Bitte bestätige uns die Datenverarbeitung<br></span>


        <div style="color: #777;padding-left:20px;  font-size: 12px">Bitte beachte außerdem unsere <a
          [routerLink]="'/datenschutz'" class="ita_view_privacy" target="_blank">Datenschutzbestimmungen</a></div>


      </div>
      <div fxLayout="column" *ngIf="additionalTermsRequired">


        <hid-checkbox inputClass="ita_check_terms-of-participation" style="font-size: 14px;"
                      [highlightErrors]="checkoutAttempted"
                      [control]="contactForm.controls['acceptAdditionalTerms']">Ich bin mit den <a
          [routerLink]="'/teilnahmebedingungen/' + page.cronosFormatId" target="_blank"
          class="ita_view_terms-of-participation">Teilnahmebedingungen</a>
          einverstanden.
        </hid-checkbox>
        <span class="hid-validationError" style="    margin-left: 65px;"
              *ngIf="checkoutAttempted.value && !contactForm.controls['acceptAdditionalTerms'].value">Bitte akzeptiere die Teilnahmebedingungen</span>
      </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="button-row" >
      <button class="hid-button inverse secondary" (click)="back()">Zurück</button>
      <button class="hid-button primary ita_finalize-checkout" (click)="attemptCheckout()">{{page?.checkoutCtaText ? page.checkoutCtaText : 'Platz Sichern'}}</button>
    </div>

  </hid-loading-spinner>
</div>
