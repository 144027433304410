<div class="banner-wrapper">
  <div class="banner-usps">
    <svg class="banner-stars" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M18 0L19.3025 4.69751L24 6L19.3025 7.30249L18 12L16.6975 7.30249L12 6L16.6975 4.69751L18 0Z" fill="#F8C910"/>
      <path d="M6 12L7.30249 16.6975L12 18L7.30249 19.3025L6 24L4.69751 19.3025L0 18L4.69751 16.6975L6 12Z" fill="#F8C910"/>
    </svg>

    <div class="usp-wrapper-start">
      <div class="banner-usp-1">
        <i class="mdi mdi-briefcase-outline"></i>
        Keine Berufserfahrung notwendig
      </div>
    </div>

    <div class="usp-wrapper-end">
      <div class="banner-usp-2">
        <i class="mdi mdi-school-outline"></i>
        Einstiegsjobs für Absolventen
      </div>
    </div>

    <div class="usp-wrapper-start">
      <div class="banner-usp-3">
        <i class="mdi mdi-file-document-outline"></i>
        Ohne Anschreiben bewerben
      </div>
    </div>


  </div>

  <div class="banner-content">
    <i class="mdi mdi-creation banner-stars"></i>
    <h1>Finde deinen Job nach der Uni</h1>
    <p>Entdecke über 5.300+ Einstiegsjobs auf unserer Jobplattform für Studenten</p>

    <a class="cta-button" [href]="link" target="_blank">Zu deinem Traumjob</a>
  </div>

  <div class="banner-image" *ngIf="!mediaService.getMaxWidthQuery(1000).matches">
    <img src="assets/img/color_correction_girl_dunkelblau%201.png" alt="">
    <svg class="banner-stars" xmlns="http://www.w3.org/2000/svg" width="38" height="35" viewBox="0 0 38 35" fill="none">
      <path d="M16 3L19.4733 15.5267L32 19L19.4733 22.4733L16 35L12.5267 22.4733L0 19L12.5267 15.5267L16 3Z" fill="#F8C910"/>
      <path d="M32 0L33.3025 4.69751L38 6L33.3025 7.30249L32 12L30.6975 7.30249L26 6L30.6975 4.69751L32 0Z" fill="#F8C910"/>
    </svg>
  </div>
</div>
